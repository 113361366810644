import { Routes, Route } from "react-router-dom"

import Home from "./pages/home/home"
import Services from "./pages/services/services"
import AboutUs from "./pages/aboutUs/aboutUs"
import ContactUs from "./pages/contactUs/contactUs"
import Login from "./pages/login/login"
import Admin from "./pages/admin/admin"

const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services/>} />
            <Route path="/about-us" element={<AboutUs/>} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<Admin />} />
        </Routes>
    )
}

export default Router