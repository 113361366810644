//styles
import './homePortfolio.scss'

//components
import Portfolio1 from '../../../assets/home/portfolio1.png'
import Portfolio2 from '../../../assets/home/portfolio2.png'

const HomePortfolio = () => {
    return (
        <div className="home-portfolio" id="portfolio">
            <div className="home-portfolio-header container">
                <div className="home-portfolio-title-container">
                    <p className="subtitle">Portfolio</p>
                    <div className="title">Our Work</div>
                </div>
            </div>
            <div className="home-portfolio-list container">
                <div className="home-portfolio-list-item" onClick={() =>{
                    window.open('https://ri-software.com.ua','_blank', 'rel=noopener noreferrer')
                }}>
                    <img src={Portfolio1} />
                    <h2 className="home-portfolio-list-item-title">
                        RI Software
                    </h2>
                    <p className="home-portfolio-list-item-description">
                        We partnered with RI Software to create a modern, user-focused website that effectively showcases their services and expertise. By implementing a sleek design, and seamless navigation, we enhanced their online presence and improved client engagement. Our efforts resulted in a 45% increase in session duration, a 30% boost in lead conversions, and a 50% growth in international traffic, solidifying their reputation as a global leader in custom software solutions.
                    </p>
                </div>
                <div className="home-portfolio-list-item" onClick={() =>{
                    window.open('https://lighttheminds.org','_blank', 'rel=noopener noreferrer')
                }}>
                    <img src={Portfolio2} />
                    <h2 className="home-portfolio-list-item-title">
                        Light The Minds
                    </h2>
                    <p className="home-portfolio-list-item-description">
                        We worked with Light The Minds, a nonprofit dedicated to helping Ukrainian students access educational opportunities in the U.S., to create a compelling and resourceful online platform. By designing a user-friendly website with clear navigation and integrating tools for scholarship applications and resources, we enhanced their ability to connect with students and donors. Our efforts led to a 50% increase in student inquiries, improved visibility among U.S. educational institutions, and strengthened their mission to empower Ukrainian youth through education.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HomePortfolio