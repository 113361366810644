//styles
import './aboutUsBanner.scss'

const AboutUsBanner = () => {
    return (
        <div className="about-us-banner">
            <div className="about-us-banner-title-container">
                <h2 className="title">Your Trusted Business Partner</h2>
                <p className="subtitle">From the idea to achived business goals.</p>
            </div>
            <div className="about-us-banner-content">
                <div className="about-us-banner-content-item">
                    <h2 className="title">300+</h2>
                    <p className="subtitle">Working people</p>
                </div>
                <div className="about-us-banner-content-item">
                    <h2 className="title">20+</h2>
                    <p className="subtitle">Worldwide offices</p>
                </div>
                <div className="about-us-banner-content-item">
                    <h2 className="title">500+</h2>
                    <p className="subtitle">Projects delivered</p>
                </div>
                <div className="about-us-banner-content-item">
                    <h2 className="title">$23M</h2>
                    <p className="subtitle">Funds raised</p>
                </div>
            </div>
        </div>
    )
}

export default AboutUsBanner