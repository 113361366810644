//styles
import './input.scss'

//react
import { FC, useEffect, useState } from 'react'

type inputProps = {
    placeholder: string
    label?: string
    type?: string
    textarea?: boolean
    error?: string
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const Input: FC<inputProps> = ({ placeholder, label, onChange, textarea, type='text', error }) => {
    const [id, setId] = useState('')

    useEffect(() => {
        setId(`${(new Date()).getTime()}`)
    }, [])

    return (
        <div className="input-wrapper">
            {
                label &&
                    <label htmlFor={id}>{label}</label>
            }
            {
                error &&
                    <p className="input-error">{error}</p>
            }
            <div className={`input ${error ? 'error' : ''}`}>
                {
                    textarea ?
                        <textarea id={id} placeholder={placeholder} onChange={(e) => onChange(e)}></textarea>
                    :
                        <input id={id} type={type} placeholder={placeholder} onChange={(e) => onChange(e)}/>
                }
            </div>
        </div>
    )
}

export default Input