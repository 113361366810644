//styles
import './burger.scss'

//react
import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//components
import Button from '../../../button/button'

type burgerProps = {
    setBurgerActive: (value: boolean) => void
}

const Burger: FC<burgerProps> = ({ setBurgerActive }) => {
    const navigate = useNavigate()

    const [ active, setActive ] = useState(false)

    const [topOffset, setTopOffset] = useState(0)
    
    useEffect(() => {
        const closeBurger = () => {
            setActive(false)
        }

        window.addEventListener('click', closeBurger)

        return () => {
            window.removeEventListener('click', closeBurger)
        }
    }, [])

    useEffect(() => {
        setBurgerActive(active)
    }, [active])

    useEffect(() => {
        const header = document.querySelector('.header')

        if(header){
            setTopOffset(header.clientHeight - 5)
        }
    }, [])

    return (
        <div className="burger" onClick={(e) => {
            e.stopPropagation()
        }}>
            <div className={`burger-header ${active && 'active'}`} onClick={() => {
                setActive(!active)
            }}>
                <div className="burger-header-item"></div>
                <div className="burger-header-item"></div>
                <div className="burger-header-item"></div>
            </div>
            <div className={`burger-content ${active && 'active'}`} onClick={() => {
                setActive(false)
            }} style={{
                top: `${topOffset}px`
            }}>
                <Link to={'/services'}>Services</Link>
                <Link to={'/#portfolio'}>Portfolio</Link>
                <Link to={'/about-us'}>About Us</Link>
                <Button text='CONTACT US' onClick={() => {
                    navigate('/contact-us')
                }}/>
            </div>
        </div>
    )
    
}

export default Burger