//styles
import './notification.scss'

//react
import { FC } from 'react'

type notificationProps = {
    open: boolean
    text: string
    error?: boolean
}

const Notification: FC<notificationProps> = ({ open, text, error=false }) => {
    return (
        <div className={`notification ${open ? 'open' : 'close'} ${error ? 'error' : ''}`}>
            <p>{text}</p>
        </div>
    )
}

export default Notification