//styles
import './button.scss'

//react
import { FC } from 'react'

type buttonProps = {
    text: string
    onClick: () => void
}

const Button: FC<buttonProps> = ({ text, onClick }) => {
    return (
        <div className="button" onClick={onClick}>
            <div className="button-boarder"></div>
            <p className="button-text">{ text }</p>
            <div className="button-boarder"></div>
        </div>
    )
}

export default Button