//styles
import './ourTeam.scss'

//components
import TeamMember1 from '../../../assets/aboutUs/teamMember1.png'
import TeamMember2 from '../../../assets/aboutUs/teamMember2.png'

const OurTeam = () => {
    return (
        <div className="our-team" id="team">
            <div className="our-team-item">
                <img src={TeamMember1} alt="" />
                <div className="our-team-text">
                    <h2 className="title">Artem Kurelenko</h2>
                    <p className="subtitle">CEO</p>
                </div>
            </div>
            <div className="our-team-item">
                <img src={TeamMember2} alt="" />
                <div className="our-team-text">
                    <h2 className="title">Rustam Ismailov</h2>
                    <p className="subtitle">Chairman</p>
                </div>
            </div>
        </div>
    )
}

export default OurTeam