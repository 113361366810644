//style
import './contactUs.scss'

//react
import { Link } from 'react-router-dom'

const ContactUs = () => {
    const arrow = <svg viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5 34.7635L34.7635 18.5M34.7635 18.5L18.5 2.23657M34.7635 18.5H2.23654" stroke="#9C7339" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
    return (
        <div className="contact-us">
            <p className="subtitle">Tell us about your project</p>
            <h2 className="title">Let's Make Something Great Together!</h2>
            <Link to='/contact-us' className='contact-us-link'>
                <h2 className="title">Contact Us</h2>
                {arrow}
            </Link>
        </div>
    )
}

export default ContactUs