//styles
import './aboutUs.scss'

//components
import Layout from '../../components/general/layout/layout'
import AboutUsInfo from '../../components/aboutUs/aboutUsInfo/aboutUsInfo'
import AboutUsBanner from '../../components/aboutUs/aboutUsBanner/aboutUsBanner'
import ContactUs from '../../components/general/contactUs/contactUs'
import OurTeam from '../../components/aboutUs/ourTeam/ourTeam'

const AboutUs = () => {
    return (
        <Layout>
            <div className="about-us">
                <div className="about-us-hero container">
                    <h2>Avout Us.</h2>
                </div>
                <div className="about-us-content container">
                    <AboutUsInfo />
                    <AboutUsBanner />
                    <OurTeam />
                    <ContactUs />
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs