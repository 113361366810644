//styles
import './emailForm.scss'
import EmailFormImg from '../../../assets/home/emailFormImg.png'

//components
import Button from '../../general/button/button'
import Input from '../../general/input/input'
import Notification from '../../general/notification/notification'

//react
import { useState } from 'react'

const EmailForm = () => {
    const [email, setEmail] = useState('')
    const [notificationOpen, setNotificationOpen] = useState(false)

    const [errors, setErrors] = useState<{
        email?: string
    }>({})

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    const handleSubmit = () => {
        setNotificationOpen(false)
        const newErrors: typeof errors = {}

        if (email.trim()) {
            newErrors.email = 'Email is required.'
        } else if (!validateEmail(email)) {
            newErrors.email = 'Please enter a valid email address.'
        }

        setErrors(newErrors)

        if (Object.keys(newErrors).length === 0) {
            setNotificationOpen(true)

            setTimeout(() => {
                setNotificationOpen(false)
            }, 5000)
            // All fields are valid, you can proceed with form submission logic here
        }
    }
    
    return (
        <div className="email-form">
            <Notification open={notificationOpen} text='Thank you for leaving your email! We will contact you back shortly.' />
            <div className="email-form-wrapper">
                <div className="email-form-container container">
                    <img src={EmailFormImg} alt="" />
                    <div className="email-form-content">
                        <h2 className="title">We will contact you</h2>
                        <p className="subtitle">Leave your email and we will contact you</p>
                        <div className="email-form-input-container">
                            <Input placeholder='Enter your Email' onChange={(e) => {
                                setEmail(e.target.value)
                            }} />
                            <Button text='SUBMIT' onClick={() => {
                                handleSubmit()
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailForm