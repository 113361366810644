//styles
import './contactUs.scss'
import { contactUsIcon } from '../../assets/contactUs/contactUsIcons'

//react
import { useEffect, useRef, useState } from 'react'

//components
import Input from '../../components/general/input/input'
import Layout from '../../components/general/layout/layout'
import Button from '../../components/general/button/button'
import Notification from '../../components/general/notification/notification'

//network
import { unauthorizedRequest } from '../../network/requests'
import { contactUrl } from '../../network/urls'

const ContactUs = () => {
    const [data, setData] = useState<{
        name: string
        email: string
        message: string
    }>({
        name: '',
        email: '',
        message: ''
    })

    const [errors, setErrors] = useState<{
        name?: string
        email?: string
        message?: string
    }>({})

    const [notificationData, setNotificationData] = useState<{
        error: boolean
        text: string
        open: boolean
    }>({
        error: false,
        text: '',
        open: false
    })

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    const handleSubmit = () => {
        setNotificationData({
            ...notificationData,
            open: false
        })
        const newErrors: typeof errors = {}

        if (!data.name.trim()) {
            newErrors.name = 'Full Name is required.'
        }

        if (!data.email.trim()) {
            newErrors.email = 'Email is required.'
        } else if (!validateEmail(data.email)) {
            newErrors.email = 'Please enter a valid email address.'
        }

        if (!data.message.trim()) {
            newErrors.message = 'Message is required.'
        }

        setErrors(newErrors)

        if (Object.keys(newErrors).length === 0) {

            unauthorizedRequest(contactUrl, 'POST', {
                name: data.name,
                email: data.email,
                message: data.message
            })
            .then((response) => {
                if(response.result){
                    setNotificationData({
                        error: false,
                        text: 'Thank you for your request! We will contact you back soon!',
                        open: true
                    })

                    setTimeout(() => {
                        setNotificationData({
                            ...notificationData,
                            open: false
                        })
                    }, 5000)
                }else{
                    setNotificationData({
                        error: true,
                        text: 'Something went wrong, please try again later.',
                        open: true
                    })

                    setTimeout(() => {
                        setNotificationData({
                            ...notificationData,
                            open: false
                        })
                    }, 5000)
                }
            })
        }
    }

    return (
        <Layout>
            <div className="contact-us-page">
                <Notification open={notificationData.open} text={notificationData.text} error={notificationData.error} />
                <div className="contact-us-wrapper container">
                    <div className="content-us-content">
                        <div className="content-us-content-header">
                            <h2 className="title">Contact Us</h2>
                            <p className="subtitle">Book a call or write us to discover our different options. Whether it's membership for recurring missions or more "classic" quotes for big projects, we have the right package for you.</p>
                        </div>
                        <div className="content-us-content-inputs">
                            <Input placeholder='Full Name' label='Full Name' error={errors.name} onChange={(e) => {
                                setData({
                                    ...data,
                                    name: e.target.value
                                })
                            }}/>
                            <Input placeholder='Email' label='Email' error={errors.email} onChange={(e) => {
                                setData({
                                    ...data,
                                    email: e.target.value
                                })
                            }}/>
                            <Input placeholder='Message' label='Message' error={errors.message} textarea onChange={(e) => {
                                setData({
                                    ...data,
                                    message: e.target.value
                                })
                            }}/>
                            <Button text='SUBMIT' onClick={() => {
                                handleSubmit()
                            }}/>
                        </div>
                    </div>
                    {contactUsIcon}
                </div>
            </div>
        </Layout>
    )
}

export default ContactUs