//styles
import './homeAboutUs.scss'

//components
import AboutUsImage from '../../../assets/home/aboutUs.png'
import Button from '../../general/button/button'

//react
import { useNavigate } from 'react-router-dom'

const HomeAboutUs = () => {
    const navigate = useNavigate()

    return (
        <div className="home-about-us container">
                <div className="home-about-us-section">
                    <div className="home-about-us-title-container">
                        <p className="subtitle">ABOUT US</p>
                        <h2 className="title">We Create World Class Digital Solutions For All Businesses</h2>
                    </div>
                    <img src={AboutUsImage} alt="" className='home-about-us-image'/>
                    <div className="home-about-us-data-container">
                        <div className="home-about-us-data-item">
                            <h2>2 weeks</h2>
                            <p>Average project time</p>
                        </div>
                        <div className="home-about-us-data-item">
                            <h2>6 years</h2>
                            <p>Average experience of team members</p>
                        </div>
                    </div>
                </div>
                <div className="home-about-us-section">
                    <p className="home-about-us-content">
                        Welcome to Web Olympus, where innovation meets functionality, and your digital presence transforms into a powerful business asset. With a passion for creating stunning, user-centric websites and a commitment to elevating your online visibility, we are your dedicated partner in web redesign, web design and development, and SEO solutions.
                    </p>
                    <Button text='LEARN MORE' onClick={() => {
                        navigate('/contact-us')
                    }} />
                </div>
        </div>
    )
}

export default HomeAboutUs