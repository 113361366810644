//styles
import './serviceItem.scss'

//ract
import { FC } from 'react'

type serviceItemProps = {
    title: string
    subtitle: string
    img?: JSX.Element
    serviceItems: {
        title: string
        items: string[]
    }[]
    id: string
}

const ServiceItem: FC<serviceItemProps> = ({ title, subtitle, img, serviceItems, id }) => {
    return (
        <div className="service-item" id={id}>
            <div className="service-item-header">
                <h2 className='title'>{title}</h2>
                <p className='subtitle'>{subtitle}</p>
            </div>
            <div className="service-item-content">
                <div className="service-item-content-items-container">
                    {
                        serviceItems.map((serviceItem) => {
                            return (    
                                <div className="service-item-content-item">
                                    <h2 className="service-item-content-item-title">{serviceItem.title}</h2>
                                    <div className="service-item-content-item-content">
                                        {serviceItem.items.map((item) => {
                                            return (
                                                <p>{item}</p>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                { img }
            </div>
        </div>
    )
}

export default ServiceItem