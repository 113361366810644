//styles
import './adminCard.scss'

//react
import { FC, useState } from 'react'

//сcomponents
import AdminPaginationButton from '../adminPaginationButton/adminPaginationButton'

//types
import { adminCard, statusTypes } from '../../../pages/admin/admin'

//network
import { authorizedRequest } from '../../../network/requests'
import { contactUrl } from '../../../network/urls'

type adminCardProps = adminCard

const AdminCard: FC<adminCardProps> = ({ id, name, email, message, placedDate, lastUpdateDate, status }) => {

    const [ currentStatus, setCurrentStatus ] = useState<statusTypes>(status) 

    const checkMark = <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                width="800px" height="800px" viewBox="0 0 305.002 305.002"
                xmlSpace="preserve">
            <g>
            <g>
                <path d="M152.502,0.001C68.412,0.001,0,68.412,0,152.501s68.412,152.5,152.502,152.5c84.089,0,152.5-68.411,152.5-152.5
                    S236.591,0.001,152.502,0.001z M152.502,280.001C82.197,280.001,25,222.806,25,152.501c0-70.304,57.197-127.5,127.502-127.5
                    c70.304,0,127.5,57.196,127.5,127.5C280.002,222.806,222.806,280.001,152.502,280.001z"/>
                <path d="M218.473,93.97l-90.546,90.547l-41.398-41.398c-4.882-4.881-12.796-4.881-17.678,0c-4.881,4.882-4.881,12.796,0,17.678
                    l50.237,50.237c2.441,2.44,5.64,3.661,8.839,3.661c3.199,0,6.398-1.221,8.839-3.661l99.385-99.385
                    c4.881-4.882,4.881-12.796,0-17.678C231.269,89.089,223.354,89.089,218.473,93.97z"/>
            </g>
            </g>
        </svg>

    const cross = <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" stroke="none" fill-rule="evenodd">
        <g fill="#fff" transform="translate(-568, -1087)">
            <path d="M584,1117 C576.268,1117 570,1110.73 570,1103 C570,1095.27 576.268,1089 584,1089 C591.732,1089 598,1095.27 598,1103 C598,1110.73 591.732,1117 584,1117 Z M584,1087 C575.163,1087 568,1094.16 568,1103 C568,1111.84 575.163,1119 584,1119 C592.837,1119 600,1111.84 600,1103 C600,1094.16 592.837,1087 584,1087 Z M589.717,1097.28 C589.323,1096.89 588.686,1096.89 588.292,1097.28 L583.994,1101.58 L579.758,1097.34 C579.367,1096.95 578.733,1096.95 578.344,1097.34 C577.953,1097.73 577.953,1098.37 578.344,1098.76 L582.58,1102.99 L578.314,1107.26 C577.921,1107.65 577.921,1108.29 578.314,1108.69 C578.708,1109.08 579.346,1109.08 579.74,1108.69 L584.006,1104.42 L588.242,1108.66 C588.633,1109.05 589.267,1109.05 589.657,1108.66 C590.048,1108.27 590.048,1107.63 589.657,1107.24 L585.42,1103.01 L589.717,1098.71 C590.11,1098.31 590.11,1097.68 589.717,1097.28 Z"/>
        </g>
        </g>
    </svg>

    const formatDate = (date: Date): string => {
        return new Intl.DateTimeFormat('en-US', {
            weekday: 'long', // e.g., Monday
            year: 'numeric', // e.g., 2025
            month: 'long', // e.g., January
            day: 'numeric', // e.g., 6
            hour: 'numeric', // e.g., 10 AM
            minute: 'numeric', // e.g., 15
            second: 'numeric', // e.g., 30
        }).format(date)
    }

    return (
        <div className="admin-card">
            <div className="admin-card-data-item">
                <p className="subtitle">Name: </p>
                <p className="admin-card-data-text">{name}</p>
            </div>
            <div className="admin-card-data-item">
                <p className="subtitle">Email: </p>
                <p className="admin-card-data-text">{email}</p>
            </div>
            <div className="admin-card-data-item">
                <p className="subtitle">Status: </p>
                <p className="admin-card-data-text">{currentStatus}</p>
            </div>
            <div className="admin-card-data-item">
                <p className="subtitle">Lats Update Date: </p>
                <p className="admin-card-data-text">{formatDate(lastUpdateDate)}</p>
            </div>
            <div className="admin-card-data-item">
                <p className="subtitle">Placed Date: </p>
                <p className="admin-card-data-text">{formatDate(placedDate)}</p>
            </div>
            <div className="admin-card-data-item">
                <p className="subtitle">Message: </p>
                <p className="admin-card-data-text">{message}</p>
            </div>
            {
                currentStatus !== statusTypes.closed &&
                    <AdminPaginationButton
                        onClick={() => {
                            authorizedRequest(contactUrl, 'PUT', {contact_id: id, status: currentStatus === statusTypes.new ? statusTypes.inProgress : statusTypes.closed})
                            .then((response) => {
                                setCurrentStatus(currentStatus === statusTypes.new ? statusTypes.inProgress : statusTypes.closed)
                            })
                        }}
                    >
                        {
                            currentStatus === statusTypes.new ?
                                checkMark
                            :
                                cross
                            
                        }
                    </AdminPaginationButton>
                

            }
        </div>
    )
}

export default AdminCard