//styles
import './aboutUsInfo.scss'

//components
import AboutUsContent1 from '../../../assets/aboutUs/aboutUsContent1.jpg'
import AboutUsContent2 from '../../../assets/aboutUs/aboutUsContent2.jpg'
import AboutUsContent3 from '../../../assets/aboutUs/aboutUsContent3.png'

const AboutUsInfo = () => {
    return (
        <div className="about-us-info" id="info">
            <div className="about-us-info-content-container">
                <div className="about-us-info-content-item">
                    <h2 className="title">Your Trusted Business Partner</h2>
                    <p className="subtitle">
                        At Web Olympus, we are a team of seasoned professionals driven by a common goal - to help businesses thrive in the digital landscape. With years of experience in the industry, we understand the ever-evolving nature of the web and the importance of staying ahead in the competitive online sphere.
                    </p>
                </div>
                <div className="about-us-info-content-item">
                    <img src={AboutUsContent1} alt="" />
                </div>
                <div className="about-us-info-content-item">
                    <img src={AboutUsContent2} alt="" />
                </div>
                <div className="about-us-info-content-item small-img">
                    <img src={AboutUsContent3} alt="" />
                </div>
            </div>
        </div>
    )
}

export default AboutUsInfo