//styles
import './header.scss'
import { logo } from '../footer/footer'

//react
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

//components
import Button from '../../button/button'
import Burger from './burger/burger'

const Header = () => {
    const navigate = useNavigate()
    
    const [onHero, setOnHero] = useState(true)
    const [burgerActive, setBurgerActive] = useState(false)

    const [solid, setSolid] = useState(false)

    const [mobile, setMobile] = useState(window.screen.width <= 768)

    useEffect(() => {
        const hero = document.querySelector(".hero")
        console.log(hero)
        if(hero){

            const options = {
                root: null,
                rootMargin: "0px",
                threshold: 0
            }
            
            const callback = (entries: IntersectionObserverEntry[]) => {
                const [entry] = entries
                setOnHero(entry.isIntersecting)
            }
            
            const observer = new IntersectionObserver(callback, options)

            observer.observe(hero)

            return () => observer.disconnect() // Cleanup observer on unmount
        }else{
            setSolid(true)
        }

    }, [])

    useEffect(() => {

        const hero = document.querySelector(".hero")

        if(hero){
         
            if(burgerActive || !onHero){
                setSolid(burgerActive || !onHero)
            }else{
                if(burgerActive){

                    setTimeout(() => {
                        setSolid(burgerActive || !onHero)
                    }, 400)
                }else{
                    setSolid(burgerActive || !onHero)
                }
            }   
        }else{
            setSolid(true)
        }
    }, [burgerActive, onHero])

    useEffect(() => {
        const resizeCallback = () => {
            setMobile(window.screen.width <= 768)
        }

        window.addEventListener('resize', resizeCallback)

        return () => {
            window.removeEventListener('resize', resizeCallback)
        }
    }, [])

    return (
        <div className={`header ${solid ? 'off-hero' : ''}`}>
            <Link to={'/'} className='header-logo'>{logo}</Link>

            {
                mobile ? 
                    <Burger setBurgerActive={setBurgerActive}/>
                :
                <>
                    <div className="header-nav">
                        <Link to={'/services'}>Services</Link>
                        <Link to={'/#portfolio'}>Portfolio</Link>
                        <Link to={'/about-us'}>About Us</Link>
                    </div>
                    <Button text='CONTACT US' onClick={() => {
                        navigate('/contact-us')
                    }}/>
                </>
            }
        </div>
    )
}

export default Header