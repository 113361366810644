//styles
import './feedback.scss'
import Quote from '../../../assets/home/quote.png'
import Avatar1 from '../../../assets/home/feedback1.png'

//react
import { useEffect, useRef, useState } from 'react'

const Feedback = () => {

    const arrow = <svg viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 5.81931L13 5.81931" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.84024 10.6392C5.84024 10.6392 1.00024 8.0304 1.00024 5.8208C1.00024 3.6096 5.84024 1 5.84024 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    const feedbackUnderline = <svg viewBox="0 0 556 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M554.776 0.406128V11.6086H68.1902H56.196L47.7331 18.9147L39.2702 11.6086H34.3386H0" stroke="white" stroke-opacity="0.2" stroke-width="0.974145"/>
        <path d="M548.931 4.30273V16.9665H68.1902H56.6831L48.2202 24.2726L39.7573 16.9665H34.3386H0" stroke="white" stroke-opacity="0.2" stroke-width="0.974145"/>
    </svg>
        
    const feedbackData = [
        {
            text:"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete.",
            avatar: Avatar1,
            name: 'Chester Feil',
            position: 'customer'
        },
        {
            text:"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete.",
            avatar: Avatar1,
            name: 'Chester Feil',
            position: 'customer'
        },
        {
            text:"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete.",
            avatar: Avatar1,
            name: 'Chester Feil',
            position: 'customer'
        },
        {
            text:"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete.",
            avatar: Avatar1,
            name: 'Chester Feil',
            position: 'customer'
        }
    ]

    const [page, setPage] = useState(0)
    const [blockWidth, setBlockWidth] = useState(0)

    const [perPage, setPerPage] = useState(window.screen.width <= 768 ? 1 : 2)

    const blockRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if(blockRef.current && containerRef.current){
            setBlockWidth(window.screen.width <= 768 ? blockRef.current.clientWidth : containerRef.current.clientWidth - blockRef.current.clientWidth)
        }
    }, [blockRef.current, containerRef.current])

    return (
        <div className="feedback container">
            <div className="feedback-header-container">
                <div className="feedback-title-container">
                    <p className="subtitle">Testimonials</p>
                    <h2 className="title">Feedback From Our Clients</h2>
                </div>
                <div className="feedback-header-buttons">
                    <div className={`feedback-header-button ${page === 0 ? 'disabled' : ''}`} onClick={() => {
                        if(page > 0){
                            setPage(page-1)
                        }
                    }}>
                        {arrow}
                    </div>
                    <div className={`feedback-header-button ${feedbackData.length-perPage === page ? 'disabled' : ''}`} onClick={() => {
                        if(feedbackData.length-perPage > page){
                            setPage(page+1)
                        }
                    }}>
                        {arrow}
                    </div>
                </div>
            </div>
            <div className="feedback-list-container" ref={containerRef}>
                {
                    feedbackData.map((item) => {
                        return (
                            <div className="feedback-list-item" ref={blockRef} style={{
                                transform: `translateX(-${blockWidth * page}px)`
                            }}>
                                <div className="feedback-list-item-header">
                                    <div className="feedback-list-item-header-content">
                                        <p>"{item.text}"</p>
                                    </div>
                                    {feedbackUnderline}
                                </div>
                                <div className="feedback-list-item-footer">
                                    <div className="feedback-list-item-footer-content">
                                        <img src={item.avatar} alt="" />
                                        <div className="feedback-list-item-footer-name">
                                            <h2>{item.name}</h2>
                                            <p>{item.position}</p>
                                        </div>
                                    </div>
                                    <img src={Quote} alt="" className="feedback-list-item-footer-quote" />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Feedback