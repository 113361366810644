//styles
import './login.scss'

//react
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

//components
import Layout from '../../components/general/layout/layout'
import Input from '../../components/general/input/input'
import Button from '../../components/general/button/button'

//network
import { unauthorizedRequest } from '../../network/requests'
import { loginUrl } from '../../network/urls'

const Login = () => {

    const navigate = useNavigate()

    const [ data, setData ] = useState<{
        email: string
        password: string
    }>({
        email: '',
        password: ''
    })

    const [errors, setErrors] = useState<{
        email?: string
        password?: string
    }>({})

    const handleSubmit = () => {
        setErrors({})
        unauthorizedRequest(loginUrl, 'POST', {email: data.email, password: data.password})
        .then((response) => {
            console.log(response)
            if(response === 403){
                setErrors({
                    email: 'Invalid email or password',
                    password: 'Invalid email or password'
                })
            }else{
                localStorage.setItem('accessToken', response.result.access_token)
                navigate('/admin')
            }
        })
    }

    return (
        <Layout>
            <div className="login">
                <div className="login-container container">
                    <div className="login-header">
                        <div className="title">Log In</div>
                    </div>
                    <div className="login-inputs">
                        <Input placeholder='email' error={errors.email} onChange={(e) => {
                            setData({
                                ...data,
                                email: e.target.value
                            })
                        }} />
                        <Input placeholder='password' error={errors.password} type='password' onChange={(e) => {
                            setData({
                                ...data,
                                password: e.target.value
                            })
                        }} />
                        <Button text='SUBMIT' onClick={() => {
                            handleSubmit()
                        }} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Login