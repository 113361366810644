
//react
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

//components
import Router from './router'

const App = () => {
  const { pathname } = useLocation()
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Smooth scrolling effect
    })
  }, [pathname]) // Triggers whenever the route path changes

    useEffect(() => {
      const hash = location.hash;
      if (hash) {
          const id = hash.substring(1); // Remove the '#' from the hash
          const element = document.getElementById(id);
          if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "start" });
          }
      }
  }, [location]);

  return (
    <Router />  
  )
}

export default App
