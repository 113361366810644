//styles
import './services.scss'
import { developmentIcon, maintenanceIcon } from '../../assets/services/serviceIcons'

//components
import Layout from "../../components/general/layout/layout"
import ServiceItem from '../../components/services/serviceItem/serviceItem'
import ContactUs from '../../components/general/contactUs/contactUs'

const Services = () => {
    return (
        <Layout>
            <div className="services">
                <div className="services-hero container">
                    <h2>Services.</h2>
                </div>
                <div className="services-content container">
                    <ServiceItem
                        id='development'
                        title={'Development'}
                        subtitle={'Comprehensive software and web development tailored to meet unique business needs.'}
                        img={developmentIcon}
                        serviceItems={
                            [
                                {
                                    title: 'Full Stack Development',
                                    items: [
                                        'Front-end: Modern UI/UX implementation using React, Vue, or Angular.',
                                        'Back-end: Robust APIs and databases using Node.js or Python.',
                                        'Integration: Seamless integration with third-party services.'
                                    ]
                                },
                                {
                                    title: 'Custom Web Applications',
                                    items: ['Tailored solutions for e-commerce, SaaS, or business portals.']
                                }
                            ]
                        }
                    />
                    <ServiceItem 
                        id='maintenance'
                        title={'Maintenance and Support'}
                        subtitle={'Ensure the continuous, smooth functioning of your digital assets with reliable maintenance.'}
                        img={maintenanceIcon}
                        serviceItems={
                            [
                                {
                                    title: 'Technical Support',
                                    items: [
                                        'Bug fixing and troubleshooting.',
                                    ]
                                },
                                {
                                    title: 'Performance Optimization',
                                    items: ['Speed enhancement for websites and applications.']
                                }
                            ]
                        }
                    />

                    <ServiceItem
                        id='hosting'
                        title={'Web Hosting and Domain Management'}
                        subtitle={'Secure, scalable, and reliable hosting services paired with domain management.'}
                        serviceItems={
                            [
                                {
                                    title: 'Web Hosting',
                                    items: [
                                        'Shared, VPS, and dedicated hosting options.',
                                        'Cloud hosting with AWS, Google Cloud, or Azure.'
                                    ]
                                },
                                {
                                    title: 'Domain Management',
                                    items: [
                                        'Domain registration and renewal.',
                                        'SSL certification and security enhancements.'
                                    ]
                                },
                                {
                                    title: 'Server Management',
                                    items: [
                                        'Configuration and maintenance of hosting environments.',
                                        'DNS setup and troubleshooting.'
                                    ]
                                }
                            ]
                        }
                    />
                    
                    <ServiceItem 
                        id='api'
                        title={'API Integration and Development'}
                        subtitle={'Custom API solutions to connect, enhance, and automate your business workflows.'}
                        serviceItems={
                            [
                                {
                                    title: 'Third-Party API Integration',
                                    items: [
                                        'Payment gateways: Stripe, PayPal, Razorpay.',
                                        'CRMs: HubSpot, Salesforce, Zoho.'
                                    ]
                                },
                                {
                                    title: 'Custom API Development',
                                    items: [
                                        'Building APIs for mobile and web applications.',
                                        'RESTful and GraphQL API solutions.'
                                    ]
                                },
                                {
                                    title: 'Data Synchronization',
                                    items: [
                                        'Syncing data between platforms and services.',
                                        'Real-time updates and notifications.'
                                    ]
                                },
                                {
                                    title: 'Security and Compliance',
                                    items: [
                                        'OAuth, JWT, and other authentication methods.',
                                        'Compliance with GDPR, CCPA, and other standards.'
                                    ]
                                }
                            ]
                        }
                    />

                    <ContactUs/>
                </div>
            </div>
        </Layout>
    )
}

export default Services