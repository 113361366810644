// styles
import './hero.scss'

//components
import Button from '../../general/button/button'

//react
import { useNavigate } from 'react-router-dom'

const Hero = () => {
    const navigate = useNavigate()

    return (
        <div className="home-hero hero">
            <div className="hero-container">
                <h2 className="hero-title">Digital Solution Agency</h2>
                <p className="hero-subtitle">Elevate your online presence with cutting-edge design, seamless development, and strategic SEO. Unleash the full potential of your digital journey</p>
                <Button text='GET A QUOTE' onClick={() => {
                    navigate('/contact-us')
                }}/>
            </div>
        </div>
    )
}

export default Hero